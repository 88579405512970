.blockmail {
  padding-bottom: 50px;
}
.blockmail__flex {
  display: flex;
  justify-content: space-between;
}
.blockmail__left {
  /*
    width: 775px;
    height: 871px;*/
  flex-shrink: 1;
  margin-right: 0px;
  text-align: right;
}
.blockmail__left-img {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: -300px;
  text-align: right;
}
.blockmail__left img {
  max-width: 100%;
  height: auto;
}
.blockmail__right {
  flex-shrink: 0;
  text-align: center;
  width: 568px;
  padding-top: 70px;
}

.form {
  width: 447px;
  margin: 0 auto;
}
input,
textarea {
  display: block;
  width: 100%;
  background: #ffffff;
  border: 2px solid #eaeaea;
  border-radius: 15px;
  padding: 26px 50px;
  margin-bottom: 15px;

  font-weight: 400;
  font-size: 20px;
  line-height: 1.5;
  text-align: left;
  color: #a9b3b8;
}
textarea {
  height: 160px;
  margin-bottom: 30px;
}
input:focus,
textarea:focus {
  outline: none;
  border-color: #13c671;
}
.btn {
  cursor: pointer;
  display: block;
  width: 100%;
  border: 0;
  padding: 24px;
  font-size: 24px;
  line-height: 35px;
  color: #fff;
  text-align: center;
  background-color: #099452;
  background: linear-gradient(53.73deg, #099452 0%, #13c671 100%);
  box-shadow: 0px 5px 20px rgba(9, 148, 82, 0.2);
  border-radius: 15px;
  transition: 0.2s all ease-in;
}
.btn:hover {
  opacity: 0.8;
}
.form__messages {
  margin-top: 15px;
  font-size: 18px;
  line-height: 1.67;
  text-align: left;
  color: #099452;
}
.form__messages.error {
  color: #f00;
}
@media screen and (max-width: 1469px) {
  .blockmail__right {
    width: 50%;
    padding-top: 0;
  }
}

@media screen and (max-width: 991px) {
  input,
  textarea {
    padding: 20px;
  }

  .blockmail__flex {
    flex-direction: column;
  }
  .blockmail__left {
    order: 2;
    text-align: center;
    padding-top: 50px;
  }
  .blockmail__right {
    width: auto;
    padding-top: 0;
  }
  .blockmail__left img {
    max-width: 80%;
  }
  .form {
    width: auto;
  }
}