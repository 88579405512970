.block3 .title {
  text-align: center;
  margin-bottom: 60px;
}
.block3__items {
  display: flex;
  justify-content: flex-end;
  margin: 0 -15px;
}
.block3__item {
  margin: 0 15px;
  background: #ffffff;
  box-shadow: 0px 40px 80px rgba(0, 0, 0, 0.03);
  border-radius: 50px;
  flex-shrink: 0;
  flex-grow: 0;
  width: 328px;
  margin-bottom: 200px;
}
.block3__1 {
  margin: 0 0 100px;
}
.block3__1 .block3__item:nth-child(1) {
  margin-top: 200px;
  margin-bottom: 0px;
}
.block3__1 .block3__item:nth-child(2) {
  margin-top: 100px;
  margin-bottom: 100px;
}
.block3__2 .block3__item:nth-child(odd) {
  margin-top: 200px;
  margin-bottom: 0;
}
.block3__item-img {
  height: 310px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.block3__item-img img {
  max-width: 100%;
  height: auto;
}
.block3__item-title {
  padding: 40px 40px;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.46;
  text-align: center;
}
.block3__title {
  font-weight: 400;
  font-size: 42px;
  line-height: 1.24;
  margin: 0 0 30px;
}
.block3__back {
  background: url(images/block3/back.svg) no-repeat center;
}
.block3__2 .block3__back {
  background: url(images/block3/back2.svg) no-repeat center;
}
.block3 .container {
  position: relative;
}
.block3__back .container::before {
  content: '';
  width: 108px;
  height: 108px;
  background: url(images/block3/p1.svg) no-repeat;
  position: absolute;
  left: -170px;
  top: 140px;
}
.block3__back .container::after {
  content: '';
  width: 108px;
  height: 108px;
  background: url(images/block3/p2.svg) no-repeat;
  position: absolute;
  right: -170px;
  top: 255px;
}
.block3__2 .block3__back .container::before {
  top: 85px;
}
.block3__2 .block3__back .container::after {
  top: 240px;
}
@media screen and (max-width: 1469px) {
  .block3__title {
    font-size: 30px;
  }
  .block3__item {
    width: 220px;
    margin-bottom: 100px;
  }
  .block3__item-title {
    padding: 20px;
    font-size: 20px;
  }
  .block3__2 .block3__item:nth-child(odd) {
    margin-top: 100px;
    margin-bottom: 0;
  }

  .block3__1 .block3__item:nth-child(1) {
    margin-top: 100px;
    margin-bottom: 0px;
  }
  .block3__1 .block3__item:nth-child(2) {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .block3__2 .block3__item:nth-child(odd) {
    margin-top: 100px;
    margin-bottom: 0;
  }

  .block3__item-img {
    height: 240px;
  }
  .block3 .container::before,
  .block3 .container::after {
    display: none;
  }
  .block3__item-img img {
    max-height: 80%;
    width: auto;
  }
  .block3__1 .block3__back {
    background-size: 85%;
  }
  .block3__2 .block3__back {
    background-size: 85% auto;
  }
}
@media screen and (max-width: 991px) {
  .block3__items {
    margin: 0;
    display: block;
  }
  .block3 .title {
    padding: 0 20px;
    text-align: left;
    margin: 0 0 40px;
  }
  .block3__item {
    width: auto;
    margin: 0 0 30px;
    display: flex;
    border-radius: 30px;
    align-items: center;
  }
  .block3__item-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    margin-right: 10px;
    flex-shrink: 0;
  }
  .block3__item-img img {
    max-height: 80%;
    width: auto;
  }
  .block3__item {
    margin: 0 0 30px !important;
  }
  .block3__title br {
    display: none;
  }
  .block3__title {
    font-size: 24px;
    margin: 0 0 20px;
  }
  .block3__item-title {
    font-size: 20px;
    padding: 0 15px 0 0;
    text-align: left;
  }
  .block3__back {
    background: none;
  }
  .block3__1 {
    margin: 0 0 40px;
  }
}
