html {
  min-height: 100%;
  font-size: 24px;
  font-weight: 400;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
body {
  font-family: 'Circe', sans-serif;
  box-sizing: border-box;
  background: #f2f3f5;
  color: #072738;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
