h1 {
  font-weight: 400;
  font-size: 78px;
  line-height: 1.1538;
  margin: 0 0 45px;
}
h1 b {
  font-weight: 700;
}

.list2 {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 50px;
  padding: 0;
}
.list2 li {
  width: 50%;
  display: flex;
  align-items: center;
  padding: 0 15px 0 0;
  margin: 0 0 30px;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.1;
}

.list2 li span {
  display: block;
  margin-left: 21px;
}

/**
    block1
    **************/
.block1 {
  padding: 0 0 78px;
}
.block1__left {
  padding: 65px 0 0;
}
.block1__right img {
  max-width: 100%;
  width: 100%;
  height: auto;
}

@media screen and (max-width: 1469px) {
  h1 {
    font-size: 60px;
  }
  .block1__left {
    padding: 20px 0 0;
  }
  .list2 li {
    font-size: 17px;
  }
}

@media screen and (max-width: 991px) {
  .block1 {
    padding: 0 0 40px;
  }
  .block1__left {
    padding: 0;
  }
  .block1__right {
    text-align: center;
  }
  .block1__right img {
    max-width: 80%;
  }
  .list2 {
    display: block;
  }
  .list2 li {
    width: auto;
    font-size: 20px;
    position: relative;
  }
  .list2 li img {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -14px;
    max-height: 28px;
  }
  .list2 li span {
    margin-left: 50px;
  }
  h1 {
    font-size: 44px;
    margin-bottom: 30px;
  }
}
