.block4 .title {
  margin-bottom: 60px;
}
.block4__item {
  height: 320px;
  background: #ffffff;
  border-radius: 50px;
  padding: 40px;
  display: flex;
  color: #072738 !important;
  text-decoration: none;
  margin: 0 15px;
  flex-shrink: 0;
  flex-grow: 1;
  flex-direction: column;
  background-repeat: no-repeat;
  transition: .3s all ease-in;
}
.block4__item:hover {
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
}
.block4__item-title {
  display: block;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.46;
  max-width: 248px;
}

.block4__item--1 {
  width: 447px;
  flex-grow: 0;
  padding-top: 30px;
  background-image: url(images/block4/img1.jpg);
  background-position: 100% 100%;
}
.block4__item--2 {
  background-image: url(images/block4/img2.jpg);
  background-position: 0 0;
  justify-content: flex-end;
}
.block4__item--3 {
  background-image: url(images/block4/img3.jpg);
  flex-grow: 1;
  background-position: 100% 0;
  background-size: 65%;
  justify-content: flex-end;
  width: 100%;
  flex: 1 0 50%;
}
.block4__item--mobile {
  padding: 68px 48px 48px;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0 15px;
  flex: 1 0 50%;
}
.block4__item--mobile .mobile-links {
  width: 100%;
  display: flex;
  justify-content: center;
}
.block4__item--mobile .mobile-links a {
  margin: 0 15px;
}
@media screen and (max-width: 1469px) {
  .block4__item--2 {
    background-size: 90%;
  }
  .block4__item--3 {
    background-size: 80%;
  }
  .block4__item--mobile {
    padding: 60px 0px 0;
    max-width: 400px;
  }
  .block4__item--mobile .mobile-links {
    flex-wrap: nowrap;
  }
  .block4__item--mobile .mobile-links img {
    width: 180px;
  }
  .block4__item--mobile br {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .block4__item {
    padding: 20px 30px;
    border-radius: 30px;
    margin: 0 0 15px;
    background-size: contain;
    height: 230px;
  }
  .block4__item--1 {
    width: auto;
    margin: 0 0 15px;
    background-size: cover;
  }
  .block4__item--3 {
    background-size: 80% auto;
  }
  .block4__item-title {
    font-size: 20px;
  }
  .block4__item--mobile {
    margin: 0;
    padding: 60px 0 0;
  }
  .row{
    margin: 0 0 15px;
  }
}