.list1 {
  list-style: none;
  margin: 0;
  padding: 0;
}
.list1 li {
  display: flex;
  align-items: center;
  padding: 29px 49px;
  margin: 0 0 30px;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.1;
  background: linear-gradient(90deg, rgba(120, 135, 165, 0.09) 0%, rgba(120, 135, 165, 0.04) 100%);
  border-radius: 30px;
}

.list1 li span {
  display: block;
  margin-left: 42px;
}

.block2__left {
  padding: 25px 0 0;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 991px) {
  .list1 li {
    padding: 14px 20px;
    font-size: 14px;
    margin: 0 0 15px;
  }
  .list1 li img {
    height: 24px;
  }
  .list1 li span {
    margin-left: 15px;
  }
}
